import { useSnackbar } from 'notistack'

import { Button } from '@/components/ui'

export type SnackbarVariant = 'error' | 'default' | 'success' | 'warning' | 'info' | undefined
export type SnackbarTrigger = (message: string, variant?: SnackbarVariant) => void

export const useAlert = () => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const triggerSnackbar: SnackbarTrigger = (message = '', variant: SnackbarVariant = 'error') => {
		const key = enqueueSnackbar(
			<div className="flex w-full items-start justify-between">
				<div className="flex min-h-9 flex-1 items-center pr-2">{message}</div>
				<div>
					<Button
						variant={'transparent'}
						aria-label="close"
						onClick={() => {
							closeSnackbar(key)
						}}
					>
						Close
					</Button>
				</div>
			</div>,
			{
				variant: variant,
			},
		)
	}

	return { triggerSnackbar }
}
