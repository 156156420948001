import { Link } from 'react-router-dom'

import BodyClassName from '@/components/body-class-name'
import { ErrorDisplay } from '@/components/error-display'
import ThemeColor from '@/components/theme-color'
import { Button } from '@/components/ui'

export const RelinkRequired = () => {
	return (
		<BodyClassName className="bg-neutral-50">
			<ThemeColor className="bg-neutral-50" />
			<div className="m-auto flex h-full max-w-lg flex-col p-4 text-center md:justify-center md:gap-8">
				<ErrorDisplay
					title="Oops!"
					description="Your primary account is no longer linked. Please relink or select a different primary account to continue."
					className="my-auto md:my-0"
				/>

				<div className="space-y-2">
					<Button
						asChild
						variant="destructive"
						className="w-full"
					>
						<Link to="/dashboard/accounts">Link Account</Link>
					</Button>

					<Button
						asChild
						variant="link"
						className="block w-full"
					>
						<Link to="/dashboard?relinkRequired=true">Back to Home</Link>
					</Button>
				</div>
			</div>
		</BodyClassName>
	)
}
