import { cva } from 'class-variance-authority'

export const buttonVariants = cva(
	'typography-label-large-prominent inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
	{
		variants: {
			variant: {
				default: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
				secondary: 'bg-neutral-50 text-foreground hover:bg-accent',
				outline: 'border border-input bg-neutral-50 hover:bg-accent hover:text-accent-foreground',
				success: 'bg-success text-success-foreground hover:bg-success/80',
				destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
				ghost: 'bg-black/20 text-white hover:bg-black/40',
				link: 'hover:bg-accent',
				tertiary: 'bg-primary text-primary-foreground hover:bg-primary/80',
				transparent: 'border border-white bg-transparent text-white hover:bg-black/30',
			},
			size: {
				default: 'h-11 px-4 py-3',
				sm: 'h-10 px-4 py-2.5',
				lg: 'typography-title-medium h-12 px-8',
				icon: 'h-10 w-10 rounded-full p-2 [&_svg]:size-6',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
)
