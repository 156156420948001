import { SnackbarProvider } from 'notistack'
import React from 'react'

import { useIsMobile } from '@/hooks/use-mobile.tsx'

function CustomSnackbarProvider({ children }: { children: React.ReactNode }) {
	const isMobile = useIsMobile(1024)

	return (
		<SnackbarProvider
			iconVariant={{
				error: '',
			}}
			preventDuplicate
			maxSnack={3}
			anchorOrigin={{
				vertical: isMobile ? 'bottom' : 'top',
				horizontal: isMobile ? 'center' : 'right',
			}}
			autoHideDuration={4000}
			classes={{
				containerRoot: isMobile
					? 'fixed bottom-0 left-0 right-0 flex flex-col items-center pb-20'
					: 'fixed right-4 top-16 flex flex-col items-end pt-12',
			}}
		>
			{children}
		</SnackbarProvider>
	)
}

export default CustomSnackbarProvider
