import { captureException, withScope } from '@sentry/react'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import CustomSnackbarProvider from '@/components/custom-snackbar-provider.tsx'
import { PaymentServiceProvider } from './api/services/providers/payment-service-provider.tsx'
import App from './App.tsx'
import Auth0ProviderWithNavigate from './components/auth0-provider-with-navigate.tsx'

import './index.css'
import './lib/sentry.ts'
import './utils/dates.ts'
import '@fontsource/k2d'

import { Toaster } from '@/components/ui'

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (err, query) => {
			withScope((scope) => {
				scope.setContext('query', { queryHash: query.queryHash })
				scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, '0')])
				captureException(err)
			})
		},
	}),
})

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<BrowserRouter>
			<Auth0ProviderWithNavigate>
				<PaymentServiceProvider>
					<QueryClientProvider client={queryClient}>
						<CustomSnackbarProvider>
							<App />
							<Toaster />
						</CustomSnackbarProvider>
						<ReactQueryDevtools />
					</QueryClientProvider>
				</PaymentServiceProvider>
			</Auth0ProviderWithNavigate>
		</BrowserRouter>
	</React.StrictMode>,
)
